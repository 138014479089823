import Image from './images/background_image2.jpg';
import Logo from './images/CH_Transparent_White_Text-SVG.svg';

function App() {
  return (
    <>
      <div className='flex bg-neutral drop-shadow-lg border-t-4 border-cyan-700 items-center text-center'>
        <img
          src={Logo}
          alt='Cyber Husky Logo'
          className='p-3 basis-1/12 w-1/12'
        />
        <h1 className='basis-10/12 text-6xl font-bold text-base-100'>
          Welcome to Cyber Helpdesk
        </h1>
      </div>
      <div className='flex h-screen'>
        <div className='bg-gray-200'>
          <div className='relative'>
            <div className='inset-0 bg-neutral-400 opacity-25 max-h-screen'>
              <img src={Image} alt='' className='h-full w-full object-cover' />
            </div>
            <div className='absolute inset-0 flex items-center'>
              <div className='flex-col w-1/2 float-left'>
                <div className='p-10'>
                  <h3 className='text-3xl font-bold'>
                    Microsoft Teams Integration
                  </h3>
                  <p className='text-lg font-bold mt-3'>
                    Our app is a full SaaS available through any web browser,
                    with all features accessible through the Microsoft Teams
                    app. This makes it easy for technicians and end users to
                    access and collaborate on tickets from inside of Teams - the
                    same place they collaborate on everything else.
                  </p>
                </div>
                <div className='p-10'>
                  <h3 className='text-3xl font-bold'>Ticket Staleness Timer</h3>
                  <p className='text-lg font-bold mt-3'>
                    Our app includes a timer to prevent longer tickets from
                    going stale, ensuring that every issue is addressed in a
                    timely manner.
                  </p>
                </div>
                <div className='p-10'>
                  <h3 className='text-3xl font-bold'>AI Features</h3>
                  <p className='text-lg font-bold mt-3'>
                    Cyber Helpdesk will also feature an AI driven chatbot and
                    knowledge base. The AI functionality will help you aggregate
                    and organize your knowledge base and then provide proactive
                    help to users automatically - drastically reducing the
                    amount of basic tickets you have to deal with.
                  </p>
                </div>
              </div>
              <div className='w-3/5 h-4/5 ml-48 mt-48 items-center'>
                <iframe
                  title='Pipedrive'
                  className='w-3/5 h-2/3'
                  src='https://webforms.pipedrive.com/f/6rGmSqkfAAtfLm5ctRqdRBAEBAYlGnh9XTCVexw4RW9Ec77HH4Z7s2Gn3KIYKOyP9F'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
